@if (isPreview && isOwner) {
  <div class="flex justify-end">
    <app-top-bar-item icon="close" (action)="executeClosePreviewing()" />
  </div>
} @else {
  <div class="flex justify-between items-center">
    <div>
      <a
        mat-button
        [disabled]="actionInProgress !== null"
        [routerLink]="getReturnUrl()"
        [queryParamsHandling]="isOwner ? undefined : 'preserve'">
        <mat-icon class="material-symbols-rounded">keyboard_backspace</mat-icon>
        {{ isOwner ? 'HR-Tool' : 'Alle Ergebnisse' }}
      </a>
    </div>
    @if (isOwner) {
      <div class="flex gap-2">
        @if (isDraftJob()) {
          <app-top-bar-publish
            [disabled]="!canPublish"
            [loading]="actionInProgress === 'publish'"
            (publish)="executePublishing()" />
        }

        @if (!isArchivedJob()) {
          <app-top-bar-preview
            [disabled]="actionInProgress !== null"
            (preview)="executePreviewing()" />
        }

        @if (isDraftJob()) {
          <app-top-bar-item
            icon="delete"
            [disabled]="actionInProgress !== null"
            [loading]="actionInProgress === 'discard'"
            iconTitle="Verwerfen"
            (action)="executeDiscarding()" />
        }

        @if (isPublicJob()) {
          <app-top-bar-item
            icon="archive"
            [disabled]="actionInProgress !== null"
            [loading]="actionInProgress === 'archive'"
            iconTitle="Archivieren"
            (action)="executeArchiving()" />
        }
      </div>
    }
  </div>
}
