@if (isPreview && isOwner) {
  <div class="flex flex-col items-end gap-2">
    <app-top-bar-item icon="close" (action)="executeClosePreviewing()" />
    <app-school-share-button [school]="school" />
  </div>
} @else {
  <div class="flex justify-between items-center">
    <a
      mat-button
      [disabled]="actionInProgress !== null"
      [routerLink]="getReturnUrl()"
      [queryParamsHandling]="isOwner ? undefined : 'preserve'">
      <mat-icon class="material-symbols-rounded">keyboard_backspace</mat-icon>
      {{ isOwner ? 'Meine Schule' : 'Alle Ergebnisse' }}
    </a>
    @if (isOwner) {
      <div class="flex gap-2">
        @if (!school.isPublic) {
          <app-top-bar-publish
            [disabled]="!canPublish"
            [loading]="actionInProgress === 'publish'"
            (publish)="executePublishing()" />
        }

        <app-top-bar-preview
          [disabled]="actionInProgress !== null"
          (preview)="executePreviewing()" />
      </div>
    } @else {
      <app-school-share-button [school]="school" />
    }
  </div>
}
